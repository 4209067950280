import React, { ReactElement } from "react";
import ReactPlayer from "react-player/youtube";

export interface VideoEmbedProps {
  title: string;
  url: string;
  caption?: string;
}

const VideoEmbed = (props: VideoEmbedProps): ReactElement => {
  return (
    <div
      data-aos="zoom-out-up"
      className="media-container"
      style={{ marginBottom: "25px" }}
    >
      <ReactPlayer url={props.url} controls={true} />
      {props.caption && <p>{props.caption}</p>}
    </div>
  );
};

export default VideoEmbed;
