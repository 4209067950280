import React, { ReactElement } from "react";
import LuckyBanner from "../images/LuckyBlue.png";
import bckspcy2k from "../images/bckspc-logo-y2k.png";
// import { useLocation } from "react-router-dom";

interface NavBarProps {
  links: LinkProps[];
}

interface LinkProps {
  path: string;
  label: string;
}

const NavBar = (props: NavBarProps): ReactElement => {
  const { links } = props;
  console.log(links);
  // const location = useLocation();

  const bckspcLogo = (
    <img
      id="bckspc-logo"
      src={bckspcy2k}
      style={{
        display: "block",
        margin: "auto",
        maxWidth: "66vw",
        // paddingTop: "75px",
        // paddingBottom: "55px",
      }}
    />
  );

  return (
    <div
      id="navbar-container"
      className="navbar-container"
      style={{
        backgroundColor: "#121212",
        backgroundImage: `url(${LuckyBanner})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
      }}
    >
      {bckspcLogo}
      {/* <div id="links" className="links-container">
        {links.map((link: LinkProps, idx: number) => (
          <Link
            className={`link-${
              link.path === location.pathname ? "active" : "inactive"
            }`}
            key={`link-${idx}`}
            to={link.path}
          >
            {link.label}
          </Link>
        ))}
      </div> */}
    </div>
  );
};

export default NavBar;
