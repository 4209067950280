import React, { ReactElement } from "react";

export interface TrackEmbedProps {
  title: string;
  id: string;
  caption?: string;
}

const TrackEmbed = (props: TrackEmbedProps): ReactElement => (
  <div data-aos="zoom-out" className="media-container">
    <iframe
      width="100%"
      height="300"
      scrolling="no"
      frameBorder="no"
      allow="autoplay"
      src={`https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/${props.id}&color=%23171c1a&auto_play=false&hide_related=false&show_comments=false&show_user=true&show_reposts=false&show_teaser=true&visual=true`}
    />
    {props.caption && <p>{props.caption}</p>}
  </div>
);

export default TrackEmbed;
