import React, { ReactElement } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Home from "./pages/Home";
import NavBar from "./components/NavBar";

interface Feature {
  path: string;
  label: string;
  component: ReactElement;
}

const features: Feature[] = [
  {
    path: "/",
    label: "Home",
    component: <Home />,
  },
  // {
  //   path: "/listen",
  //   label: "Listen",
  //   component: <Listen />,
  // },
  // {
  //   path: "/watch",
  //   label: "Watch",
  //   component: <Watch />,
  // },
];

const App = (): ReactElement => (
  <BrowserRouter>
    <NavBar
      links={features.map((feature: Feature) => ({
        label: feature.label,
        path: feature.path,
      }))}
    />
    <Switch>
      {features.map((feature: Feature) => (
        <Route exact path={feature.path} key={feature.label}>
          {feature.component}
        </Route>
      ))}
    </Switch>
  </BrowserRouter>
);

export default App;
