import React, { ReactElement } from "react";
import Listen from "./Listen";
import Watch from "./Watch";
import {
  SoundCloudButton,
  YoutubeButton,
} from "../components/ExternalLinkButtons";

const Home = (): ReactElement => {
  return (
    <div className="page-container">
      <Listen />
      <Watch />
      <div className="button-container">
        <SoundCloudButton />
        <YoutubeButton />
      </div>
    </div>
  );
};

// const Header = (): ReactElement => (
//   <p
//     style={{
//       textAlign: "center",
//       margin: "20px",
//       fontSize: "28px",
//       color: "white",
//     }}
//   >
//     MATERIA EP: OUT NOW
//   </p>
// );

export default Home;
